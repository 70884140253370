import { useStaticQuery } from "gatsby"
import React, { useState } from "react"
import Img from "gatsby-image"

import "./RendersInteriorExterior.scss"
import { motion } from "framer-motion"
import PageTransitionLink from "../PageTransitionLink"

const ArchitecturalRenderItem = ({ render }) => {
  const [isHovered, setIsHovered] = useState(false)

  const { renderName } = render
  const renderImageFluid =
    render.architecturalRenderImage.localFile.childImageSharp.fluid
  const contentfulRenderImageFluid = render.architecturalRenderImage.fluid

  return (
    <div
      className="arch-render-item"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (!isHovered) setIsHovered(true)
      }}
    >
      <motion.div
        // transition={{ duration: 0.3 }}
        // animate={{ opacity: isHovered ? 0.3 : 1 }}
        className="arch-render-item__image shadow-md"
      >
        <Img fluid={contentfulRenderImageFluid} />
      </motion.div>

      <div className="arch-render-item__text">
        <motion.h5
        //   transition={{ duration: 0.3 }}
        //   initial={{ scale: 0.6, opacity: 0.75 }}
        //   animate={{
        //     scale: isHovered ? 0.75 : 0.6,
        //     opacity: isHovered ? 1 : 0.75,
        //   }}
        >
          {renderName}
        </motion.h5>
      </div>
    </div>
  )
}

const RendersInteriorExterior = ({ featuredRenders, rendersPriceLine }) => {
  const priceRegex = /(\d+\d{1,2})/g
  const servicePrice = rendersPriceLine.match(priceRegex)
  return (
    <section className="renders-int-ext ">
      <h2 className="renders-int-ext__title"> 3D EXTERIOR / INTERIOR VIEWS</h2>
      <p className="renders-int-ext__description">
        Let us know the specific views you want to see of your new project and
        we’ll bring them to life!
      </p>
      <div className="renders-int-ext__price">
        Starting from: <span>${servicePrice}</span>
      </div>

      <div className="renders-int-ext__items-container">
        {featuredRenders.map(render => (
          <ArchitecturalRenderItem render={render} key={render.id} />
        ))}
      </div>

      <p className="centered-cta-line">
        Looking for a more immersive experience?{" "}
        <PageTransitionLink to="/virtual-reality/">
          Check out <span className="font-bold">Virtual Reality!</span>{" "}
        </PageTransitionLink>
      </p>
    </section>
  )
}

export default RendersInteriorExterior
