import { motion } from "framer-motion"
import React, { useEffect } from "react"
import Img from "gatsby-image"
import { IoIosArrowDown } from "react-icons/io"

import Image from "../../assets/images/images"

import "./RendersHero.scss"
import HeroMediaComponent from "../HeroMediaComponent"
import { select } from "../../utils/helpers"
import gsap from "gsap"

const RendersHero = ({ pageTitle, heroImage }) => {
  const rendersHeroImageFluid = heroImage.localFile.childImageSharp.fluid

  useEffect(() => {
    const mainHeadline = select(".renders-hero__text-content h1 span")
    const heroParagraph = select(".renders-hero__text-content p")
    const heroMedia = select(".home-hero__image, .home-hero__video")

    const tl = gsap.timeline()
    tl.from(mainHeadline, {
      y: 100,
      opacity: 0,
      duration: 1,
      ease: "power4.out",
      delay: 0.75,
    })
      .from(
        heroParagraph,
        {
          opacity: 0,
          duration: 1,
          ease: "power4.out",
        },
        "<0.75"
      )
      .from(
        heroMedia,
        {
          opacity: 0,
          duration: 2,
          ease: "power2.iOout",
        },
        "<0.75"
      )

    // tl.to("body", { opacity: 0.5 })
  }, [])

  return (
    <section className="renders-hero ">
      <HeroMediaComponent className="renders-hero__image" media={heroImage} />
      <div className="renders-hero__text-content">
        <h1 className="overflow-hidden leading-none">
          <span className="block">{pageTitle}</span>
        </h1>
        <p>
          At Max VR we use the combined power of Twinmotion, ArchiCAD and Adobe
          to create 3D photo-realistic renders from 2D plans. <br />
          {/* If you have a project that has slightly different needs,{" "}
          <a href="mailto:ino@maxvr.co.nz?subject=Max VR Virtual Reality Query">
            <span className="font-semibold"> get in touch!</span>{" "}
          </a> */}
        </p>
        <motion.div
          animate={{
            y: [0, 0, 12, 0],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.7, 0.8, 1],
            loop: Infinity,
            repeatDelay: 1,
          }}
        >
          <IoIosArrowDown className="scroll-down-chevron" stroke="0" />
        </motion.div>
      </div>
    </section>
  )
}

export default RendersHero
