import React from "react"
import RendersHero from "./RendersHero"
import RendersIntro from "./RendersIntro"
import RendersInteriorExterior from "./RendersInteriorExterior"
import PageTransitionLink from "../PageTransitionLink"
import { useStaticQuery } from "gatsby"
import SEO from "../seo"

const ArchitecturalRendersPage = () => {
  const {
    pageTitle,
    heroImage,
    featuredArchitecturalRenders,
    rendersStandaloneServiceItem,
  } = useArchitecturalRendersQuery()
  return (
    <div>
      <SEO
        title="Architectural Renders"
        description="At Max VR we use the combined power of Twinmotion, ArchiCAD and Adobe
          to create 3D photo-realistic renders from 2D plans."
      />
      <RendersHero pageTitle={pageTitle} heroImage={heroImage} />
      {/* <RendersIntro /> */}
      <RendersInteriorExterior
        featuredRenders={featuredArchitecturalRenders}
        rendersPriceLine={
          rendersStandaloneServiceItem.standAloneServicePriceLine
        }
      />
    </div>
  )
}

export default ArchitecturalRendersPage

const useArchitecturalRendersQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        contentfulArchitecturalRendersPage {
          pageTitle
          heroImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            file {
              contentType
              url
            }
            localFile {
              publicURL
            }
          }
          rendersStandaloneServiceItem {
            standAloneServicePriceLine
          }
          featuredArchitecturalRenders {
            id
            renderName
            architecturalRenderImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              fluid(maxWidth: 800, quality: 75) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    `
  )
  return data.contentfulArchitecturalRendersPage
}
