import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

const Image = props => {
  const { src, className = "" } = props
  const data = useStaticQuery(graphql`
    query {
      panorama8k: file(relativePath: { eq: "Panorama 8k.png" }) {
        childImageSharp {
          fixed(height: 4096, width: 8192, quality: 95) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      panorama8kMin: file(relativePath: { eq: "Panorama 8k-min.png" }) {
        childImageSharp {
          fixed(height: 4096, width: 8192, quality: 95) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      architecturalRenders: file(
        relativePath: { eq: "architectural-renders.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      virtualReality: file(relativePath: { eq: "virtual-reality.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      #   a1: file(relativePath: { eq: "dummy/Tresidder_-_4.webp" }) {
      #     childImageSharp {
      #       fluid(maxHeight: 1440, quality: 90) {
      #         ...GatsbyImageSharpFluid_withWebp
      #       }
      #     }
      #   }
      a2: file(relativePath: { eq: "dummy/Tresidder_-_5__1_.webp" }) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      a3: file(relativePath: { eq: "dummy/Weka_1_-28Evening-29.webp" }) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      a4: file(relativePath: { eq: "architectural-renders.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      a5: file(relativePath: { eq: "virtual-reality.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  if (!data[src]) {
    console.error("image not found:", src)
  }

  const eagerImages = ["home1"]
  const loading = eagerImages.includes(src) ? "eager" : "lazy"

  return (
    <GatsbyImage
      {...props}
      fluid={data[src].childImageSharp.fluid}
      className={`img img--${src} ${className}`}
      loading={loading}
    />
  )
}
export default Image
